var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('app-text-field',{staticClass:"mr-5",attrs:{"label":"Data","solo":"","flat":"","background-color":"grey lighten-4","type":"date"},on:{"change":function($event){return _vm.select()}},model:{value:(_vm.params.date_start),callback:function ($$v) {_vm.$set(_vm.params, "date_start", $$v)},expression:"params.date_start"}}),_c('app-text-field',{staticClass:"ml-5",attrs:{"label":"Data","solo":"","flat":"","background-color":"grey lighten-4","type":"date"},on:{"change":function($event){return _vm.select()}},model:{value:(_vm.params.date_end),callback:function ($$v) {_vm.$set(_vm.params, "date_end", $$v)},expression:"params.date_end"}})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('h4',[_vm._v(_vm._s(_vm.getAccountPlanGroup("1.1").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(" Conta ")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('1.1')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("1.1", month))))])])]})],2)],2)]},proxy:true}])}),_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.getAccountPlanGroup("2.1").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(" Conta ")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('2.1')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("2.1", month))))])])]})],2)],2)]},proxy:true}])}),_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.getAccountPlanGroup("2.2").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Conta")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('2.2')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("2.2", month))))])])]})],2)],2)]},proxy:true}])}),_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.getAccountPlanGroup("2.3").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Conta")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('2.3')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("2.3", month))))])])]})],2)],2)]},proxy:true}])}),_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.getAccountPlanGroup("2.4").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Conta")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('2.4')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("2.4", month))))])])]})],2)],2)]},proxy:true}])}),_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.getAccountPlanGroup("1.2").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Conta")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('1.2')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("1.2", month))))])])]})],2)],2)]},proxy:true}])}),_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.getAccountPlanGroup("2.5").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Conta")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('2.5')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("2.5", month))))])])]})],2)],2)]},proxy:true}])}),_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.getAccountPlanGroup("1.3").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Conta")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('1.3')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("1.3", month))))])])]})],2)],2)]},proxy:true}])}),_c('h4',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.getAccountPlanGroup("2.6").name))]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Conta")]),_vm._l((_vm.months),function(month,index){return [_c('th',{key:index,staticClass:"text-right",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(month)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.getAccountPlanGroup('2.6')
            .account_plans),function(account_plan,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(account_plan.name)+" ")]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right",on:{"click":function($event){return _vm.handleAccountPlanClick(account_plan.id, month)}}},[_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.getAccountPlanValue(account_plan.id, month)))+" ")])]})],2)]}),_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_vm._l((_vm.months),function(month,index){return [_c('td',{key:index,staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.$format.decimal(_vm.getAccountPlanGroupSum("2.6", month))))])])]})],2)],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }