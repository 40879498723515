<template>
  <div class="d-flex">
    <div>
      <!-- <span class="fa-stack">
        <i class="fas fa-circle fa-stack-2x text-muted"></i>
         <i
          :class="[
            origin[accountTransaction.origin].icon,
            'fa-stack-1x fa-inverse',
          ]"
        ></i>
      </span> -->
      <v-icon>mdi-swap-horizontal</v-icon>
    </div>
    <div class="ml-2">
      <span v-if="isIncomingTransfer"> Entrada de Transferência </span>
      <span v-else> Saída de Transferência </span>
      | {{ originAccountDescription }} <v-icon small>mdi-arrow-right</v-icon> {{ destinationAccountDescription }}
      <template v-if="accountTransaction.bank_statement_transaction">
        <v-icon small>mdi-link-variant</v-icon>
      </template>
      <v-chip class="ml-2" color="error" x-small v-if="accountTransaction.status == 'canceled'">
        Cancelado
      </v-chip>
      <br />
      <small class="text--secondary">
        {{ $format.dateTimeBr(accountTransaction.created_at) }}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accountTransaction: {},
  },

  data: () => ({
    origin: {
      o: {
        text: "Vendas",
        icon: "fas fa-shopping-cart",
      },
      br: {
        text: "Contas a receber",
        icon: "fas fa-file-invoice-dollar",
      },
      bp: {
        text: "Contas a pagar",
        icon: "fas fa-file-invoice-dollar",
      },
      c: {
        text: "Lançamento",
        icon: "fas fa-pen",
      },
      t: {
        text: "Transferência",
        icon: "fas fa-exchange-alt",
      },
      dd: {
        text: "Ajuste de Caixa",
        icon: "fas fa-hand-holding-usd",
      },
    },
  }),

  computed: {
    isIncomingTransfer() {
      return this.accountTransaction.transfer_origin_account_id;
    },

    originAccountDescription() {
      if (this.isIncomingTransfer) {
        return this.accountTransaction.transfer_origin_account.name;
      } else {
        return this.accountTransaction.account.name;
      }
    },

    destinationAccountDescription() {
      if (this.isIncomingTransfer) {
        return this.accountTransaction.account.name;
      } else {
        return this.accountTransaction.transfer_destination_account.name;
      }
    },
  },
};
</script>

<style>
</style>