<template>
  <div class="d-flex">
    <div>
      <v-icon>{{ getIcon(accountTransaction.origin) }}</v-icon>
      <!-- <span class="fa-stack">
        <i class="fas fa-circle fa-stack-2x text-muted"></i>
        <i
          :class="[
            origin[accountTransaction.origin].icon,
            'fa-stack-1x fa-inverse',
          ]"
        ></i>
      </span> -->
    </div>
    <div class="ml-2">
      <span>{{ accountTransaction.description }} 
        <template v-if="accountTransaction.bank_statement_transaction">
          <v-icon small>mdi-link-variant</v-icon>
        </template>
      </span>
      <v-chip class="ml-2" color="error" x-small v-if="accountTransaction.status == 'canceled'">
        Cancelado
      </v-chip>
      <template v-if="accountTransaction.origin == 'br'">
        <br>
        <small class="text--disabled">{{ accountTransaction.person.name }}</small>
      </template>
      <template v-if="accountTransaction.account_plan">
        <br />
        <small class="text--secondary">
          {{ accountTransaction.account_plan.name }}
        </small>
        <br />
        <small class="text--secondary">
          {{ $format.dateTimeBr(accountTransaction.created_at) }}
        </small>
      </template>
      <br />
      <!-- <small class="text-muted"
        >{{ accountTransaction.paid_at | dateBr }}</small
      > -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accountTransaction: {
      default () {
        return {
          origin : 'order'
        }
      }
    },
  },
  
  data: () => ({
    origin: {
      order: {
        text: "Vendas",
        icon: "mdi-cart-outline",
      },
      bill_payable : {
        text: "Contas a receber",
        icon: "mdi-cash-minus",
      },
      // bp: {
      //   text: "Contas a pagar",
      //   icon: "fas fa-file-invoice-dollar",
      // },
      custom: {
        text: "Lançamento",
        icon: "mdi-pencil",
      },
      // t: {
      //   text: "Transferência",
      //   icon: "fas fa-exchange-alt",
      // },
      // dd: {
      //   text: "Ajuste de Caixa",
      //   icon: "fas fa-hand-holding-usd",
      // },
    },
  }),

  methods : {
    getIcon() {
      try {
        return this.origin[this.accountTransaction.origin].icon 
      } catch (e) {
        return null
      }
    }
  }
};
</script>

<style>
</style>