<template>
  <div class="py-2">
    <custom-account-transaction-label
      :account-transaction="accountTransaction"
      v-if="accountTransaction.origin !== 'transfer'"
    />
    <transfer-account-transaction-label
      :account-transaction="accountTransaction"
      v-if="accountTransaction.origin == 'transfer'"
    />
  </div>
</template>

<script>

import CustomAccountTransactionLabel from "@/components/account/sections/CustomAccountTransactionLabel.vue";
import TransferAccountTransactionLabel from "@/components/account/sections/TransferAccountTransactionLabel.vue";

export default {
  components: {
    CustomAccountTransactionLabel,
    TransferAccountTransactionLabel,
  },

  props: {
    accountTransaction: {},
  },

  data: () => ({
    origin: {
      o: {
        text: "Vendas",
        icon: "fas fa-shopping-cart",
      },
      br: {
        text: "Contas a receber",
        icon: "fas fa-file-invoice-dollar",
      },
      bp: {
        text: "Contas a pagar",
        icon: "fas fa-file-invoice-dollar",
      },
      c: {
        text: "Lançamento",
        icon: "fas fa-pen",
      },
      t: {
        text: "Transferência",
        icon: "fas fa-exchange-alt",
      },
      dd: {
        text: "Ajuste de Caixa",
        icon: "fas fa-hand-holding-usd",
      },
    },
  }),
};
</script>

<style>
</style>