<template>
  <v-dialog v-model="dialog" max-width="600px" eager origin="center">
    <v-card>
      <v-card-title>
        Lançamento
        <v-btn
          @click="handlePrint()"
          class="ml-auto text-capitalize"
          color="primary"
          text
          icon
        >
          <app-icon>print</app-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <small class="text-secondary">Descrição</small>
            <br />
            <span class="text--primary">
              <account-transaction-label
                :account-transaction="accountTransaction"
              />
            </span>
          </v-col>
        </v-row>
        <v-row v-if="accountTransaction.details">
          <v-col>
            <small class="text-secondary">Detalhes</small>
            <br />
            <span class="text--primary" style="white-space: pre">
              {{ accountTransaction.details }}
              <app-shortcut-btn
                name="SaleView"
                v-if="accountTransaction.origin == 'sale'"
                :params="accountTransaction.sale_id"
              />
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <small class="text-secondary">Data</small>
            <br />
            <span class="text--primary">
              {{ $format.dateBr(accountTransaction.date) }}
            </span>
          </v-col>
          <v-col>
            <small class="text-secondary">Valor</small>
            <br />
            <span class="text--primary">
              <app-money-label
                :value="accountTransaction.value"
                :canceled="accountTransaction.status == 'canceled'"
              />
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-space-between py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          v-if="$acl.can('accountTransactionCancel')"
          :disabled="!canCancel"
          text
          class="text-capitalize"
          color="error"
          @click="handleCancel()"
          >Cancelar Lançamento</v-btn
        >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Voltar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccountTransactionLabel from "@/components/account/sections/AccountTransactionLabel.vue";

export default {
  components: { AccountTransactionLabel },

  data: () => ({
    dialog: false,
    id: null,
    accountTransaction: {},
  }),

  computed: {
    canCancel() {
      return this.accountTransaction.status != "canceled";
    },
  },

  methods: {
    async open(id) {
      this.id = id;

      await this.showAccountTransaction();

      this.dialog = true;
    },

    async showAccountTransaction() {
      this.$store.commit("app/showLoading");

      let response = await this.$http.show(
        "account/account-transaction",
        this.id
      );

      this.accountTransaction = response.accountTransaction;

      this.$store.commit("app/hideLoading");
    },

    async handleCancel() {
      this.$store.commit("app/showLoading");
      await this.$http
        .destroy("account/account-transaction", this.id)
        .then(async () => {
          await this.showAccountTransaction();
          this.$emit("store");
        })
        .catch((error) => {});
      this.$store.commit("app/hideLoading");
    },

    handlePrint() {
      let params = {
        origin: this.accountTransaction.origin,
        origin_id: null,
      };

      if (params.origin == "sale") {
        params.origin_id = this.accountTransaction.sale_id;
      }
      if (params.origin == "bill_receivable") {
        params.origin_id = this.accountTransaction.bill_receivable_id;
      }
      if (params.origin == "bill_receivable_group") {
        params.origin_id =
          this.accountTransaction.bill_receivable_payment_group_id;
      }

      this.$print.preview(params);
    },
  },
};
</script>

<style>
</style>