<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="computedDateFormatted"
          label="Data"
          readonly
          solo
          flat
          background-color="grey lighten-4"
          v-on="on"
        ></v-text-field>
          <!-- prepend-inner-icon="mdi-chevron-left"
          append-icon="mdi-chevron-right" -->
        <!-- prepend-inner-icon="mdi-chevron-left"
          append-icon="mdi-chevron-right" -->
      </template>
      <v-date-picker
        locale="pt-Br"
        no-title
        scrollable
        v-model="date"
        @input="$refs.menu.save(date)"
        :type="type"
      >
        <v-spacer></v-spacer>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {},

    dateRangeType: {
      default: "date",
    },

    type: {
      default: "date",
    },
  },

  data: () => ({
    date: null,

    dateFormatted: null,

    menu: false,
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    value(val) {
      this.date = val;
    },

    date(val) {
      this.dateFormatted = this.formatDate(this.date);
      console.log(val);
      this.$emit("input", val);
    },
  },

  created() {
    this.date = this.value
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return this.type == "date"
        ? `${day}/${month}/${year}`
        : `${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>